import React from 'react';
import PropTypes from 'prop-types';

function Person({ image, name, description }) {
	return (
		<div className="person">
			<img className="person__image" src={image}></img>

			<h3 className="person__name">{name}</h3>
			<p className="person__description">{description}</p>
		</div>
	);
}

Person.propTypes = {
	image: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};

export default Person;
