import React from 'react';
import PropTypes from 'prop-types';

const EngagementModel = ({ header, body, isRightAligned }) => {
	return (
		<div className="engagementModel">
			<div
				className={
					isRightAligned
						? 'engagementModel__headerContainer--right'
						: 'engagementModel__headerContainer--left'
				}
			>
				<div className="engagementModel__headerCircle"></div>
				<h2 className="engagementModel__header">{header}</h2>
			</div>
			<div className="engagementModel__body">{body}</div>
		</div>
	);
};

EngagementModel.propTypes = {
	header: PropTypes.string.isRequired,
	body: PropTypes.string.isRequired,
	isRightAligned: PropTypes.bool,
};

export default EngagementModel;
