import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Navbar from '../components/navigation/Navbar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Contact from '../components/pages/contact/Contact';
import Home from '../components/pages/home/Home';
import Team from '../components/pages/team/Team';

import Footer from '../components/footer/Footer';

function App() {
	const [navVisible, setNavVisibility] = useState(false);
	return (
		<Router>
			<div>
				<Navbar visible={navVisible} changeVisibility={setNavVisibility}></Navbar>
				<Switch>
					<Route exact path="/contact" render={() => <Contact burgerOpen={navVisible} />} />
					<Route exact path="/team" render={() => <Team burgerOpen={navVisible} />} />
					<Route path="/" render={() => <Home burgerOpen={navVisible} />} />
				</Switch>
				<Footer />
			</div>
		</Router>
	);
}

ReactDOM.render(<App />, document.getElementById('root'));
