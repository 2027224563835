import React, { Component } from 'react';
import EngagementModel from './EngagementModel';
import { Link } from 'react-router-dom';

export class Rules extends Component {
	render() {
		return (
			<div className="section rules">
				<h2 className="rules__header header_font font_poppins">Rules of Engagement</h2>
				<div className="rules__container">
					<div className="rules__description">
						Besides embodying Agile, we’re very flexible in the ways we interact with our clients. You can
						choose a model that fits you the most, or we can devise something new together!
					</div>
					<div className="rules__body">
						<EngagementModel
							header="Fixed Price"
							body="You already have a clear image of what you need—in your mind or in a presentation? Great! Because in this case, we can tell you exactly how much it will cost to make your vision real."
						></EngagementModel>
						<EngagementModel
							header="Step-by-Step"
							body="The vision of your product is not yet complete? That’s fine, too: we can split the development process into stages, making the elements you’ve decided on materialize first."
							isRightAligned={true}
						></EngagementModel>
						<EngagementModel
							header="Dedicated Team"
							body="You would like a dedicated team of professionals to work on your project to maximize the engagement? That’s entirely possible, too: we’ll set one up for you."
						></EngagementModel>
						<EngagementModel
							header="Unique Mix"
							body="Your case is special and you would like to combine approaches and/or implement different ones for separate stages of your project? Let’s talk about it!"
							isRightAligned={true}
						></EngagementModel>
					</div>
					<div className="rules__actioncontainer">
						<Link className="rules__actionbutton" to="/contact">
							Contact
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default Rules;
