import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Abstract } from '../../../../images';
import VrImage from './VrImage';

export class Landing extends Component {
	render() {
		return (
			<div className="section_landing row-space-between">
				<div className="intro">
					<img src={Abstract}></img>
					<h2>KOEMO</h2>
					<p>Development without hussle</p>
					<Link to="/contact">Get in Touch</Link>
				</div>
				<div className="vr-img">
					<VrImage></VrImage>
				</div>
			</div>
		);
	}
}

export default Landing;
