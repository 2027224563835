module.exports = {
	AILogo: require('./AI.svg'),
	DBLogo: require('./db.svg'),
	QualityLogo: require('./quality.svg'),
	SupervisionLogo: require('./supervision.svg'),
	UXLogo: require('./UIUX.svg'),
	WebDevLogo: require('./browser.svg'),
	Abstract: require('./abstract.svg'),
	Pipedrive: require('./pipedrive2.svg'),
	HalfCircle: require('./half-circle.svg'),
	AbstractCircles: require('./abstract-circles.svg'),
	Office: require('./office.jpg'),
	Kristo: require('./kristo_no_bg.png'),
	Mark: require('./mark_no_bg.png'),
	Villem: require('./villem_no_bg.png'),
	Marten: require('./matu_no_bg.png'),
	UT: require('./ut.svg'),
	Swedbank: require('./swedbank_logo.svg'),
	Easypark: require('./easypark.svg'),
	OfficeBuilding: require('./office-building.jpeg'),
};
