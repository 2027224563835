import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export function NavLink({ link, desc, action }) {
	return (
		<li className={action ? 'nav-action' : 'nav-link'}>
			<Link to={link}>{desc}</Link>
		</li>
	);
}

NavLink.propTypes = {
	link: PropTypes.string.isRequired,
	desc: PropTypes.string.isRequired,
	action: PropTypes.bool,
};

export default NavLink;
