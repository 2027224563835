import React from 'react';

function Footer() {
	return (
		<div className="footer">
			<span className="footer__copyright">Koemo OÜ &copy; {new Date().getUTCFullYear()}</span>
		</div>
	);
}

export default Footer;
