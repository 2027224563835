import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export function BurgerNavLink({ animated, link, desc }) {
	return (
		<li className={animated ? 'link-animation' : ''}>
			<Link to={link}>{desc}</Link>
		</li>
	);
}

BurgerNavLink.propTypes = {
	link: PropTypes.string.isRequired,
	animated: PropTypes.bool,
	desc: PropTypes.any,
};

export default BurgerNavLink;
