import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export default class Form extends Component {
	state = {
		name: '',
		email: '',
		phone: '',
		message: '',
	};

	onNameChange(event) {
		this.setState({ name: event.target.value });
	}

	onEmailChange(event) {
		this.setState({ email: event.target.value });
	}

	onPhoneChange(event) {
		this.setState({ phone: event.target.value });
	}

	onMessageChange(event) {
		this.setState({ message: event.target.value });
	}

	handleSubmit(event) {
		event.preventDefault();
		emailjs.sendForm('gmail', 'koemo', event.target, 'user_w4vGwQhxwLVb86HHvJ3tJ').then(
			() => {
				NotificationManager.success(`We'll get in touch`, 'Email Sent!', 5000);
				this.setState({
					name: '',
					email: '',
					phone: '',
					message: '',
				});
			},
			error => {
				console.log(error.text);
				NotificationManager.error('Something Went Wrong', 'Failed to send email', 5000);
			},
		);
	}

	render() {
		return (
			<form className="form" method="POST" onSubmit={this.handleSubmit.bind(this)}>
				<div className="form__section">
					<label htmlFor="name">TELL US YOUR NAME *</label>
					<input
						id="name"
						type="text"
						value={this.state.name}
						onChange={this.onNameChange.bind(this)}
						name="name"
						placeholder="Name"
						required
					></input>
				</div>

				<div className="form__section">
					<label htmlFor="email">ENTER YOUR EMAIL *</label>
					<input
						id="email"
						type="email"
						value={this.state.email}
						onChange={this.onEmailChange.bind(this)}
						name="email"
						placeholder="Eg. example@email.com"
						required
					></input>
				</div>

				<div className="form__section">
					<label htmlFor="phone">ENTER PHONE NUMBER</label>
					<input
						id="phone"
						type="tel"
						value={this.state.phone}
						onChange={this.onPhoneChange.bind(this)}
						name="phone"
						placeholder="Eg. +372 55555555"
					></input>
				</div>

				<div className="form__section">
					<label htmlFor="message">MESSAGE *</label>
					<textarea
						id="message"
						name="message"
						type="text"
						value={this.state.message}
						onChange={this.onMessageChange.bind(this)}
						placeholder="Write us a message"
						rows={20}
					></textarea>
				</div>

				<input type="submit" value="SEND!" />
				<NotificationContainer />
			</form>
		);
	}
}
