import React from 'react';
import PropTypes from 'prop-types';

import Landing from './sections/Landing';
import Offer from './sections/offer/Offer';
import Rules from './sections/rules/Rules';

function Home({ burgerOpen }) {
	return (
		<div className={burgerOpen ? 'hidden' : ''}>
			<Landing />
			<Offer />
			<Rules />
		</div>
	);
}

Home.propTypes = {
	burgerOpen: PropTypes.bool.isRequired,
};

export default Home;
