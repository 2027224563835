import React, { Component } from 'react';
import Offering from './Offering';
import { AILogo, DBLogo, QualityLogo, SupervisionLogo, UXLogo, WebDevLogo, HalfCircle } from '../../../../../images';

export class Offer extends Component {
	render() {
		return (
			<div className="section_gray">
				<div className="bgImage">
					<img src={HalfCircle} />
				</div>

				<div className="section_header">
					<h2 className="font_poppins header_font">What We Offer</h2>
				</div>
				<div className="offerings_container">
					<Offering header="Artificial Intelligence" logoSrc={AILogo}></Offering>
					<Offering header="Database Engineering" logoSrc={DBLogo}></Offering>
					<Offering header="Web Development" logoSrc={WebDevLogo}></Offering>
					<Offering header="UI/UX" logoSrc={UXLogo}></Offering>
					<Offering header="Maintainability" logoSrc={QualityLogo}></Offering>
					<Offering header="Supervision" logoSrc={SupervisionLogo}></Offering>
				</div>
			</div>
		);
	}
}

export default Offer;
