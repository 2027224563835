import React from 'react';
import { OfficeBuilding } from '../../../images';
import PropTypes from 'prop-types';

import Form from './Form';

export function Contact({ burgerOpen }) {
	const gradient = 'linear-gradient(45deg, rgba(0, 0, 0, 0.9), rgba(0,0,0, 0.9))';
	return (
		<div className={burgerOpen ? 'hidden' : 'contact'}>
			<div className="contact__details" style={{ backgroundImage: `${gradient}, url(${OfficeBuilding})` }}>
				<div>
					<h3>Email</h3>
					<p>info@koemo.ee</p>
				</div>

				<div>
					<h3>Address</h3>
					<p>R. Tobiase 9, Tallinn, 10125, Estonia</p>
				</div>
			</div>
			<div className="contact__form">
				<Form></Form>
			</div>
		</div>
	);
}

Contact.propTypes = {
	burgerOpen: PropTypes.bool.isRequired,
};

export default Contact;
