import React from 'react';
import PropTypes from 'prop-types';

const Offering = ({ header, logoSrc }) => {
	return (
		<div className="offering_square">
			<p className="font_poppins subheader_font">{header}</p>
			<img src={logoSrc}></img>
		</div>
	);
};

Offering.propTypes = {
	header: PropTypes.string.isRequired,
	logoSrc: PropTypes.string.isRequired,
};

export default Offering;
