import React from 'react';
import PropTypes from 'prop-types';
import Burger from './Burger';
import BurgerNavLink from './BurgerNavLink';
import NavLink from './NavLink';
import { Link } from 'react-router-dom';

export function Navbar({ visible, changeVisibility }) {
	return (
		<nav className={`${visible ? 'nav-active' : ''}`}>
			{!visible && (
				<>
					<Link className="nav-logo" to="/">
						KOEMO
					</Link>
					<ul className="nav-links">
						<NavLink link="/team" desc="Team"></NavLink>
						<NavLink action={true} link="/contact" desc="Contact"></NavLink>
					</ul>
				</>
			)}

			{visible && (
				<ul
					className={`burger-nav-links ${visible ? 'burger-active' : ''}`}
					onClick={() => changeVisibility(!visible)}
				>
					<BurgerNavLink link="/" desc="Homepage" animated={visible}></BurgerNavLink>
					<BurgerNavLink link="/team" desc="Team" animated={visible}></BurgerNavLink>
					<BurgerNavLink link="/contact" desc="Contact" animated={visible}></BurgerNavLink>
				</ul>
			)}

			<Burger changeVisiblity={changeVisibility} open={visible}></Burger>
		</nav>
	);
}

Navbar.propTypes = {
	visible: PropTypes.bool.isRequired,
	changeVisibility: PropTypes.func.isRequired,
};

export default Navbar;
