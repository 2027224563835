import React from 'react';
import Person from './Person';
import { Office, Kristo, Mark, Villem, Marten } from '../../../images';
import PropTypes from 'prop-types';

function Team({ burgerOpen }) {
	return (
		<div className={burgerOpen ? 'hidden' : ''}>
			<div className="banner" style={{ backgroundImage: `url(${Office})` }}>
				<h2 className="banner__header">Our Team</h2>
			</div>
			<div className="team-intro">
				We are a bunch of cutting edge web developers / designers / project managers / UI & UX designers, who
				can figure out new and innovative ways to bring even your most complex ideas to life.
			</div>
			<div className="people">
				<Person image={Kristo} name="Kristo Karp" description="UI/UX designer, Frontend Developer" />
				<Person image={Marten} name="Marten Jaago" description="Backend Developer" />
				<Person image={Mark} name="Mark-Eerik Kodar" description="Full-Stack Developer" />
				<Person image={Villem} name="Villem-Oskar Ossip" description="AI/Backend Developer" />
			</div>
		</div>
	);
}

Team.propTypes = {
	burgerOpen: PropTypes.bool.isRequired,
};

export default Team;
