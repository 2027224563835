import React from 'react';
import PropTypes from 'prop-types';

export function Burger({ open, changeVisiblity }) {
	return (
		<div className={`burger ${open ? 'burger-open' : ''}`} onClick={() => changeVisiblity(!open)}>
			<div className="line1"></div>
			<div className="line2"></div>
			<div className="line3"></div>
		</div>
	);
}

Burger.propTypes = {
	open: PropTypes.bool.isRequired,
	changeVisiblity: PropTypes.func.isRequired,
};

export default Burger;
